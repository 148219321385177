import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
  SpacePartial,
  TestIdPartial,
  AccessibilityLabelPartial,
} from '../../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/rn-button.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        disableCodeEditing
        code={snippet}
        platform="react-native"
        gitHubLink="buttons/button"
        layout="rn"
      />

      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="buttonStyle" types={['string']}>
            <Text>Determines the button style.</Text>
            <List type="unordered">
              <li>
                <code>standard</code> (default)
              </li>
              <li>
                <code>minimal</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="buttonType" types={['string']}>
            <Text>Determines what type appears on the button.</Text>
            <List type="unordered">
              <li>
                <code>primary</code> (default)
              </li>
              <li>
                <code>secondary</code>
              </li>
              <li>
                <code>destroy</code>
              </li>
              <li>
                <code>confirm</code>
              </li>
              <li>
                <code>subtle</code>
              </li>
              <li>
                <code>cancel</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="icon" types={['element']}>
            <Text>Adds an icon element to the button.</Text>
          </PropListItem>

          <PropListItem name="iconAlignment" types={['string']}>
            <Text>Determines what side of the label the icon displays.</Text>
            <List type="unordered">
              <li>
                <code>left</code> (default)
              </li>
              <li>
                <code>right</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="isBlock" types={['boolean']}>
            <Text>
              Dictates whether or not the width fills 100% of the button’s
              container.
            </Text>
            <List type="unordered">
              <li>
                <code>true</code> (default)
              </li>
              <li>
                <code>false</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="isDisabled" types={['boolean']}>
            <Text>Determines whether the button is disabled.</Text>
          </PropListItem>

          <PropListItem name="size" types={['string']}>
            <Text>Determines the button’s size.</Text>
            <List type="unordered">
              <li>
                <code>small</code>
              </li>
              <li>
                <code>medium</code> (default)
              </li>
              <li>
                <code>large</code>
              </li>
            </List>
          </PropListItem>

          <SpacePartial />

          <PropListItem name="status" types={['string']}>
            <Text>Adds a status indicator to the button.</Text>
            <List type="unordered">
              <li>
                <code>spinning</code> - Adds a spinner next to the text.
              </li>
              <li>
                <code>success</code> - Makes the button green and adds a
                checkmark next to the text.
              </li>
              <li>
                <code>failure</code> - Makes the button red and adds an X next
                to the text.
              </li>
            </List>
          </PropListItem>

          <PropListItem name="text" types={['string']}>
            <Text>The text that appears in the button.</Text>
          </PropListItem>

          <PropListItem name="overflowStyle" types={['string']}>
            <Text>
              Determines whether to truncate text that overflows the width of
              the button with an ellipsis or to wrap it to another line with a
              hyphen.
            </Text>
            <List type="unordered">
              <li>
                <code>ellipsis</code>
              </li>
              <li>
                <code>wrap</code> (default)
              </li>
            </List>
          </PropListItem>
        </PropList>

        <PropList header="Events">
          <PropListItem name="onPress" types={['() => void']} isRequired>
            <Text>Responds to the button press event.</Text>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <AccessibilityLabelPartial componentName="button" />

          <TestIdPartial componentName="button" />

          <PropListItem name="...props" types={[]}>
            <Text>
              You can pass in any of the other props from a{' '}
              <Link href="https://reactnative.dev/docs/touchableopacity">
                touchable opacity
              </Link>{' '}
              component.
            </Text>
          </PropListItem>
        </PropList>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React Native"
        componentName="Button"
      />
    </PlatformTab>
  );
};

export default ReactNativeTab;
