import React from 'react';
import Loadable from 'react-loadable';
import {
  CodeSnippet,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from '../../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const Button = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Button;
    return <Component {...props} />;
  },
});

const AppleTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, Button: Button }}
        code="let button: UniformButton"
        platform="apple"
        gitHubLink="Button"
        disableCodeEditing
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="Type" types={['string']}>
            <Text>Determines the type of button.</Text>
            <List type="unordered">
              <li>
                <code>.subtle = 0</code> (default)
              </li>
              <li>
                <code>.primary</code>
              </li>
              <li>
                <code>.secondary</code>
              </li>
              <li>
                <code>.destroy</code>
              </li>
              <li>
                <code>.confirm</code>
              </li>
              <li>
                <code>.cancel</code> (only for style minimal)
              </li>
            </List>
          </PropListItem>
          <PropListItem name="Style" types={['string']}>
            <Text>Determines the button style.</Text>
            <List type="unordered">
              <li>
                <code>.standard = 0</code> (default)
              </li>
              <li>
                <code>.minimal</code>
              </li>
            </List>
          </PropListItem>
          <PropListItem name="Size" types={['string']}>
            <Text>Determines the button’s size.</Text>
            <List type="unordered">
              <li>
                <code>.small = 0</code>
              </li>
              <li>
                <code>.medium</code> (default)
              </li>
              <li>
                <code>.large</code>
              </li>
            </List>
          </PropListItem>
          <PropListItem name="Environment" types={['string']}>
            <Text>The environment in which the button is displayed.</Text>
            <List type="unordered">
              <li>
                <code>.light = 0</code> (default)
              </li>
              <li>
                <code>.dark</code>
              </li>
            </List>
          </PropListItem>
          <PropListItem name="isSpinning" types={['bool']}>
            <Text>
              Determines whether to show the loading spinner. Defaults to false.
            </Text>
          </PropListItem>
          <PropListItem name="isEnabled" types={['bool']}>
            <Text>
              Determines whether the button is enabled. Defaults to true.
            </Text>
          </PropListItem>
        </PropList>
      </Section>
      <Section title="Functions">
        <PropList>
          <PropListItem name="update" types={['function']}>
            <Text>
              Updates any or all of the following 4 properties. If any are not
              specified, the current value of that prop is used. Arguments
              include:
            </Text>
            <List type="unordered">
              <li>
                <code>type: Type</code>
              </li>
              <li>
                <code>size: Size</code>
              </li>
              <li>
                <code>style: Style</code>
              </li>
              <li>
                <code>environment: Environment</code>
              </li>
            </List>
          </PropListItem>
          <PropListItem name="setImage" types={['function']}>
            <Text>Sets the icon on the button. Arguments Include:</Text>
            <List type="unordered">
              <li>
                <code>image: UIImage?</code>
              </li>
              <li>
                <code>for state: UIControlState</code>
              </li>
            </List>
          </PropListItem>
        </PropList>
      </Section>

      <Section title="Notes">
        <List type="unordered">
          <li>
            Make sure you set the <code>UIButtonType</code> (different than the
            Uniform <code>ButtonType</code>) to custom in code/IB.
          </li>
          <li>
            Unless you need a non-standard height/width, the button will size
            itself based on the set size property.
          </li>
          <li>
            For block-style buttons, constrain leading and trailing to the
            superview.
          </li>
          <li>
            Default values can be set from Interface Builder. However, IB does
            not yet support enums as IBInspectable values—use the corresponding
            integer value instead.
          </li>
          <li>
            Spinners for every style and type will not be properly tinted.
          </li>
        </List>
      </Section>
    </PlatformTab>
  );
};

export default AppleTab;
