import React from 'react';
import {
  ColorDataTableCode,
  Link,
  PlatformTab,
  Section,
  Paragraph,
} from '../../../../components';

const AndroidTab = ({ data }) => {
  return (
    <PlatformTab>
      <Paragraph>
        This Uniform component doesn't exist for Android. For now, you can
        follow{' '}
        <Link href="https://material.io/guidelines/components/buttons.html">
          Material guidelines
        </Link>{' '}
        with the Uniform color variables below.
      </Paragraph>

      <Section title="Main Colors">
        <ColorDataTableCode data={data} platform="android" />
      </Section>
      <Section title="Light Environment">
        <ColorDataTableCode
          env="light"
          colorsType="light"
          data={data}
          platform="android"
        />
      </Section>

      <Section title="Dark Environment">
        <ColorDataTableCode
          env="dark"
          colorsType="dark"
          data={data}
          platform="android"
        />
      </Section>
    </PlatformTab>
  );
};

export default AndroidTab;
